import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { graphql, useStaticQuery } from 'gatsby';
import { RichText, RichTextProps, Elements } from 'prismic-reactjs';

const htmlSerializer: RichTextProps['htmlSerializer'] = function (
  type,
  element,
  content,
  children,
  key,
) {
  switch (type) {
    // Add a class to paragraph elements
    case Elements.paragraph:
      const isBr =
        !children ||
        children.length === 0 ||
        (children.length === 1 && children[0] === null);

      return React.createElement(
        isBr ? 'br' : 'p',
        { key },
        isBr ? undefined : children,
      );

    // Return null to stick with the default behavior
    default:
      return null;
  }
};

const ContactPage = ({
  data,
}: {
  data: GatsbyTypes.ContactPageQueryQuery;
}): JSX.Element => (
  <Layout>
    <SEO title="CV" />
    <article className="prose">
      {data?.prismicContact?.data?.content?.raw && (
        <RichText
          render={data?.prismicContact?.data?.content?.raw}
          htmlSerializer={htmlSerializer}
        />
      )}
    </article>
  </Layout>
);

export const query = graphql`
  query ContactPageQuery {
    prismicContact {
      data {
        content {
          raw
        }
      }
    }
  }
`;

export default ContactPage;
